<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="fleet-match-content">
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header" class="clearfix">
          <span>配置基础信息</span>
        </div>
        <YkcDetailItemPlus label="电站名称">
          {{ dealData(detail.stationName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="归属商户">
          {{ dealData(detail.stationOperatorName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="修改人">
          {{ dealData(detail.modifiedAccount) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus label="修改时间">
          <div class="ellipsis">
            {{ dealData(detail.modifiedTime) }}
          </div>
        </YkcDetailItemPlus>
      </YkcDetailPlus>
      <div style="margin: 10px 0">
        <ykc-table
          title="标准价格明细"
          :data="detail.priceList || []"
          :columns="priceListColumns"
          :headerButtons="priceListHeaderButtons.filter(item => item.enabled())"></ykc-table>
      </div>
      <div>
        <ykc-table
          title="价格编辑记录"
          :data="priceEditRecord"
          :columns="recordTableColumns"
          :tableConfig="recordTableConfig"
          :operateButtons="recordTableOperateButtons"></ykc-table>
      </div>
    </div>
  </scroll-layout>
</template>

<script>
  import { chargeStrategy } from '@/service/apis';
  import { code, offlineExport } from '@/utils';

  import openDialog from './dialog';

  export default {
    name: 'SellingPriceDetail',
    data() {
      return {
        showDetail: false,
        fleetTypeNew: [],
        detail: {},
        priceEditRecord: [],
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        recordTableOperateButtons: [
          {
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.showRecordDetail(row);
                  }}>
                  详情
                </ykc-button>
              );
            },
          },
        ],
        recordTableColumns: [
          { label: '编辑时间', prop: 'modifiedTime' },
          { label: '编辑人', prop: 'modifiedAccount' },
          {
            label: '状态',
            prop: 'status',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row: { status } }) => {
                return (
                  {
                    0: '已停止',
                    1: '当前价格',
                    2: '待生效',
                  }[status] || '一'
                );
              },
            },
          },
        ],
        recordTableConfig: {
          maxHeight: '500',
        },
        priceListColumns: [
          {
            label: '时段',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const { priceStartPeriod, priceEndPeriod, defaultFlag } = row;
                if (String(defaultFlag) === '1') {
                  return '其余时段';
                }
                const parse = id => {
                  id = Number(id);
                  const index = id - 1;
                  const hour = Math.floor(index / 2);
                  const minute = (index % 2) * 30;
                  const name = `${`${hour}`.padStart(2, '0')}:${`${minute}`.padStart(2, '0')}`;
                  return name;
                };
                return `${parse(priceStartPeriod)}~${parse(priceEndPeriod)}`;
              },
            },
          },
          {
            label: '电费',
            scopedSlots: {
              default: ({ row: { powerRate } }) => `${powerRate}元/度`,
            },
          },
          {
            label: '服务费',
            scopedSlots: {
              default: ({ row: { serviceRate } }) => `${serviceRate}元/度`,
            },
          },
        ],
        priceListHeaderButtons: [
          {
            enabled: () => code('operation:price:charge:detailexport'),
            id: 'export',
            text: '导出',
            handleClick: this.exportData,
          },
        ],
      };
    },
    created() {
      this.getDetail();
    },
    methods: {
      exportData() {
        const { stationId } = this.$route.query;
        offlineExport(
          {
            downloadType: 'station_price_export',
            jsonNode: {
              downloadKey: 'charge_price_detail_list',
              stationId,
            },
          },
          48
        );
      },
      showRecordDetail(row) {
        openDialog(row);
      },
      getDetail() {
        const { stationId } = this.$route.query;
        chargeStrategy.detail({ stationId }).then(res => {
          this.detail = res;
        });
        chargeStrategy.history({ stationId }).then(res => {
          console.log('history res', res);
          this.priceEditRecord = res;
        });
      },
      dealData(data, append) {
        const label = append ? `${data + append}` : data;
        return label || '——';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .fleet-match-content {
    display: flex;
    flex-flow: column;
    .box-card {
      .title {
        color: inherit;
      }
      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  table {
    width: 100%;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    th {
      height: 44px;
    }
    td {
      height: 40px;
    }
    th,
    td {
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  }
</style>
